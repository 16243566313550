import React from "react"
import * as styles from "./index.module.scss"
import Slider from "react-slick"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const items = [
  {
    src: require(`./images/7.jpg`).default,
    altText: "",
    caption: "",
  },
  {
    src: require(`./images/1.jpg`).default,
    altText: "",
    caption: "",
  },
  {
    src: require(`./images/5.jpg`).default,
    altText: "",
    caption: "",
  },
  {
    src: require(`./images/2.jpg`).default,
    altText: "",
    caption: "",
  },
  {
    src: require(`./images/6.jpg`).default,
    altText: "",
    caption: "",
  },
  {
    src: require(`./images/3.jpg`).default,
    altText: "",
    caption: "",
  },
  {
    src: require(`./images/4.jpg`).default,
    altText: "",
    caption: "",
  },
]

const SlideShow = props => {
  const slides = items.map((item, idx) => {
    return (
      <div key={idx}>
        <img src={item.src} alt={item.altText} />
      </div>
    )
  })

  return (
    <div className={styles.slider_wrapper}>
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-lg-4 col-xl-3">
            <h1>
              <span>Welcome to</span>
              Owita Naturals
            </h1>
          </div>
          <div className="col-md-7 col-lg-8 col-xl-9">
            <div className={styles.carousel_wrapper}>
              <div>
                <Slider {...settings}>{slides}</Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SlideShow
